@import "~/styles/theme.less";
@import "~antd/lib/style/themes/default.less";

.loginFormStyle {

}

.opeartions {
  display: flex;
  justify-content: space-between;
  margin: 0 -12px;
  margin-bottom: 12px;
}
