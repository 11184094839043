@import "~/styles/theme.less";
@import '~/styles/theme.less';

.logWrapper {
  text-align: center;
}
.logo {
  width: 160px;
  height: 60px;
  transform: scale(0.6);
  transition: opacity 0.2s linear;
}

.logo_sm {
  width: 64px;
  height: 64px;
  transition: opacity 0.2s linear;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @border-color-split;
}
.header_light {
  background-color: #ffffff;
}

.header_dark {
  color: #ffffff;
  background-color: @layout-sider-background;
}

.header_left {
}

.header_right {
}

.content {
  padding: 16px;
}
