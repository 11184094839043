@import "~/styles/theme.less";
@import '~antd/lib/style/themes/default.less';

.noticeList {
  min-width: 400px;
  max-height: 550px;
  overflow-y: auto;
}
.noticeItem {
  overflow: hidden;
  cursor: pointer;
  word-break: break-all;
  transition: all 0.33s;
  &:hover {
    background: @primary-1;
  }
  .read {
    opacity: 0.7;
  }
}
.message {
  display: flex;
  align-items: center;
}
.description {
  padding-left:10px;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unread {
  background-color: #87d068;
}